import * as React from 'react'
import { useRouter } from 'next/router'
import { GetStaticProps } from 'next'
import apolloClient from '@/lib/utils/apollo-client'
import getCraftSiteHandle from '@/lib/utils/get-craft-site-handle'
import Seo from '@/components/Seo'
import AppHeader from '@/components/AppHeader'
import AppFooter from '@/components/AppFooter'
import dynamic from 'next/dynamic'
import getPreviewToken from '@/lib/utils/get-preview-token'

import { QUERY_SITE_WIDE } from '@/gql/shared-queries'
import { QUERY_STANDARD_PAGE } from '@/gql/shared-queries'

import { StandardPageQuery_entry_page_page_Entry } from '@/gql/types/StandardPageQuery'
import { SiteWideQuery_globalSet } from '@/gql/types/SiteWideQuery'
import { FragmentImage } from '@/gql/types/FragmentImage'

type StandardPageType = {
    entry: StandardPageQuery_entry_page_page_Entry
    siteWide: SiteWideQuery_globalSet
}

const componentList: { [key: string]: React.ComponentType<any> } = {
    primaryBanner: dynamic(() => import(`@/components/Blocks/Modules/PrimaryBanner`)),
    copy: dynamic(() => import(`@/components/Blocks/Modules/Copy`)),
    quote: dynamic(() => import(`@/components/Blocks/Modules/Quote`)),
    logo: dynamic(() => import(`@/components/Blocks/Modules/Logo`)),
    callToAction: dynamic(() => import(`@/components/Blocks/Modules/CallToAction`)),
    textImage: dynamic(() => import(`@/components/Blocks/Modules/TextImage`)),
    steps: dynamic(() => import(`@/components/Blocks/Modules/Steps`)),
    informationCards: dynamic(() => import(`@/components/Blocks/Modules/InformationCards`)),
}

const StandardPage: React.FunctionComponent<StandardPageType> = ({ entry, siteWide }) => {
    const router = useRouter()
    const metaImage = entry.metaImage![0] as FragmentImage

    // TODO: Fix TypeScript any values
    const components = entry.modules!.map((module: any) => {
        return { name: componentList[module!.typeHandle], data: module }
    })

    const getBackgroundVideoState = components
        ?.map((component) => component.data.backgroundVideo)
        .filter((component) => component)[0]

    const backgroundVideo = getBackgroundVideoState === 'yes' ? true : false

    return (
        <>
            <Seo
                siteWide={siteWide}
                title={entry.metaTitle!}
                description={entry.metaDescription!}
                image={metaImage}
                canonical={`${router.asPath}`}
            />
            <AppHeader mode="fixed" siteWide={siteWide} entry={entry} bgVideo={backgroundVideo} />
            {components.map((Component, index) => {
                return <Component.name key={index} data={Component.data} siteWide={siteWide} />
            })}
            <AppFooter siteWide={siteWide} bgColor="bg-black" />
        </>
    )
}

export const getStaticProps: GetStaticProps = async ({ params, locale, preview, previewData }) => {
    const previewToken = getPreviewToken(previewData, preview)

    const { data } = await apolloClient(previewToken).query({
        query: QUERY_STANDARD_PAGE,
        variables: {
            site: getCraftSiteHandle(locale),
            slug: 'index',
        },
    })

    const { data: siteWide } = await apolloClient(previewToken).query({
        query: QUERY_SITE_WIDE,
    })

    if (data.entry === null) {
        return {
            notFound: true,
            revalidate: 1,
        }
    }

    return {
        props: {
            entry: data.entry as StandardPageQuery_entry_page_page_Entry,
            siteWide: siteWide.globalSet as SiteWideQuery_globalSet,
        },
        revalidate: 1,
    }
}

export default StandardPage
